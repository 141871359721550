@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* width */
::-webkit-scrollbar {
  height: 6px;
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #E6E6E6;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #cccccc;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #7367F0;
  cursor: grab;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Fonts */
@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap');

@layer components {
  .social-button {
    @apply rounded-md w-12 h-10 flex items-center justify-center gap-4 text-sm;
  }

  .center {
    @apply flex items-center justify-center;
  }

  .vertical-center {
    @apply flex items-center;
  }

  .list-bullet {
    @apply size-2 transition-all duration-300 rounded-full;
  }

  .transition {
    @apply transition-all duration-300;
  }

   .no-scrollbar::-webkit-scrollbar {
      display: none;
    }

    .no-scrollbar {
      -ms-overflow-style: none; 
      scrollbar-width: none;
  }
}

@layer components {
  /* Social Button */
  .social-button {
    @apply rounded-md w-12 h-10 flex items-center justify-center gap-4 text-sm;
  }

  /* Center Alignment */
  .center {
    @apply flex items-center justify-center;
  }

  /* Vertical Center Alignment */
  .vertical-center {
    @apply flex items-center;
  }

  /* Bullet List Style */
  .list-bullet {
    @apply size-2 transition-all duration-300 rounded-full;
  }

  /* Transition Effects */
  .transition {
    @apply transition-all duration-300;
  }

  /* No Scrollbar */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  /* Policy Page Styles */
  .policy-container {
    @apply font-nunito my-6 px-4 md:px-8 lg:px-12 text-gray-800;
  }

  .policy-section {
    @apply my-6;
  }

  .policy-heading {
    @apply text-2xl font-extrabold text-gray-900 mb-4;
  }

  .policy-text {
    @apply text-base leading-7 text-gray-700 mb-4;
  }

  .policy-list {
    @apply list-disc list-inside mt-3 space-y-1 text-gray-700;
  }

  .policy-link {
    @apply text-blue-600 underline;
  }

  .policy-link:hover {
    @apply text-blue-800;
  }
}